<template>
  <div>
    <v-col class="text-right">
      <app-btn class="mr-2" @click="dialog.show(undefined, { formTitle: 'Send Wifi Config', sendConfig: true })"
        v-if="type === 2">
        <v-icon>mdi-account-plus-outline</v-icon> Send Wifi Config
      </app-btn>
      <app-btn @click="dialog.show()">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Wifi Config
      </app-btn>
    </v-col>
    <material-card icon="mdi-account-group" icon-small color="primary"
      :title="(type == 1 ? selectedCompanyName : 'Device') + ' Wifi Configs'">
      <v-card-text>
        <v-text-field v-model="search" append-icon="mdi-magnify" class="ml-auto" hide-details label="Search records"
          single-line style="max-width: 250px" />

        <v-divider class="mt-3" />

        <data-table-wrapper :headers="headers" :items.sync="items" :search.sync="search">
          <template v-slot:top>
            <form-dialog v-model="dialog" title="Wifi Config" :default-value="defaultItem" @save="save">
              <template v-slot:[`form`]="{ item, options }">
                <v-col cols="12">
                  <v-text-field v-model="item.essid" label="ESSID" />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="item.password" label="Password" />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="item.encryption" label="Encryption" />
                </v-col>
                <v-col cols="12" v-if="!options.sendConfig">
                  <v-switch v-model="item.active" label="Active" />
                </v-col>
              </template>
            </form-dialog>

            <user-prompt v-model="deleteDialog" @confirm="deleteConfirm" />
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-icon v-if="item.active" color="success">
              mdi-check-circle
            </v-icon>
            <v-icon v-else color="error">
              mdi-close-circle
            </v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon medium color="info" class="mr-2"
              @click="dialog.show(item, { formTitle: 'Send Wifi Config', sendConfig: true })">
              mdi-send-variant
            </v-icon>

            <v-icon medium color="info" class="mr-2" @click="dialog.show(item, { index })">
              mdi-pencil
            </v-icon>

            <v-icon medium color="error" @click="deleteDialog.show(item)">
              mdi-delete
            </v-icon>
          </template>
        </data-table-wrapper>
      </v-card-text>
    </material-card>

  </div>
</template>
<script>
import { get } from 'vuex-pathify'
import store from '../store'

export default {
  name: 'WifiConfigs',
  props: {
    type: Number,
    referenceId: Number
  },
  components: {},
  data() {
    return ({
      dialog: null,
      deleteDialog: null,
      headers: [
        { text: 'ESSID', value: 'essid' },
        { text: 'Password', value: 'password' },
        { text: 'Encryption', value: 'encryption' },
        { text: 'Active', value: 'active' },
        { text: 'Actions', value: 'actions', align: 'end' }
      ],
      items: [],
      defaultItem: {
        active: true,
        essid: "",
        password: "",
        encryption: "",
      },
      search: undefined,
    })
  },

  computed: {
    ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
    companies() {
      return store.getters['company/getCompanies']
    },
  },

  watch: {
    type() {
      this.items = []
      this.getConfigurations().then(data => {
        this.items = data
      })
    },
    referenceId() {
      this.items = []
      this.getConfigurations().then(data => {
        this.items = data
      })
    },
  },

  created() {
    this.getConfigurations().then(data => {
      this.items = data
    })
  },

  methods: {
    async getConfigurations() {
      try {
        if (this.type == 1) {
          const res = await this.$http(`/WifiConfig/company-configs/${this.referenceId}`)
          if (res.data?.success) {
            return res.data.data
          }
        } else if (this.type == 2) {
          const res = await this.$http(`/WifiConfig/device-configs/${this.referenceId}`)
          if (res.data?.success) {
            return res.data.data
          }
        }
      } catch (error) {

      }

      return []
    },

    save(editedItem, { index, sendConfig }) {
      if (!this.referenceId || this.referenceId == 0) {
        this.$toast.error(`${this.type == 1 ? 'Company' : 'Device'} not found.`, 'Error', {
          position: 'topRight',
        })
        return
      }

      if (sendConfig) {
        this.$http.post(`/Message/SendWifiConfigsTodevice?deviceId=${this.referenceId}`, editedItem).then((response) => {
          if (response.data.success) {
            this.$toast.success('Config sent successfully.', 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
        return
      }

      if (index > -1) {
        this.$http.put(`/WifiConfig/update/${editedItem.id}`, editedItem).then((response) => {
          if (response.data.success) {
            this.$toast.success('Config added successfully.', 'Success', {
              position: 'topRight',
            })
            this.items.splice(index, 1, response.data.data)
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      } else {
        editedItem.referenceId = this.referenceId
        editedItem.type = this.type
        this.$http.post('/WifiConfig/CreateWifiConfig', editedItem).then((response) => {
          if (response.data.success) {
            this.$toast.success('Config added successfully.', 'Success', {
              position: 'topRight',
            })
            this.items.unshift(response.data.data)
            if (response.data?.data?.active) {
              this.items = this.items.map(x => {
                x.active = x.id === response.data.data.id
                return x;
              })
            }
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      }
    },

    deleteConfirm(item) {
      this.$http.delete(`/WifiConfig/${item.id}`).then(response => {
        if (response.data.success) {
          this.$toast.success('Config removed successfully.', 'Success', {
            position: 'topRight',
          })

          const index = this.items.indexOf(item)
          this.items.splice(index, 1)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },

  },
}
</script>
